@import "styles/button.scss";

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap;
}

* {
  box-sizing: border-box;
}
.App {
  text-align: center;
  font-size: 16px;
  cursor: none !important;
  .cursor {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App__header {
  display: flex;
  max-height: 100px;
  padding: 3rem 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background: #61dafb;
  img {
    max-width: 250px;
  }
}

.App-content {
  padding: 20px;
  &--content {
    display: flex;
    width: 25vw;
    align-items: center;
    flex-direction: column;
    h1,
    h2,
    h3,
    h4 {
      font-family: Cyber;
      font-size: 1.2rem;
      line-height: 1.5em;
      padding: 0;
      margin: 0;
    }
  }
}

.App-link {
  color: #61dafb;
}

button {
  font-family: "Cyber", sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
